/* General Styles */

body {
  background-color: #000000;
  font-family: 'Roboto', sans-serif;
  color: #E0E0E0;
}
.work-section {
  background: linear-gradient(rgba(0, 0, 0, 0.532), rgba(0, 0, 0, 0.402)), 
  url('https://images.pexels.com/photos/807598/pexels-photo-807598.jpeg') no-repeat center center fixed;
  background-size: cover;
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.work-title {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #D4AF37;
  margin-bottom: 3rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.work-container {
  width: 90%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

@media (min-width: 768px) {
  .work-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns on desktop */
  }
}

.project-card {
  height:100%;
  background-color: #333333;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.project-card:hover {
  transform: translateY(-10px);
}


.image-carousel {
  position: relative;
  height: 320px; /* מגדיל את גובה הקרוסלה */
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* תוודא שהתמונה מכסה את כל השטח שניתן */
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.project-content {
  padding: 1.5rem;
}

.carousel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Project Info Styles */

.project-title {
  font-size: 1.5rem;
  color: #D4AF37;
  margin-bottom: 1rem;
  margin-left: 2%;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-left: 2%;
  width: 80%;
  height: 10%;
}

.project-technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2%;
}

.project-technology {
  background-color: rgba(255, 255, 255, 0.1);
  color: #D4AF37;
  padding: 0.3rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
}

.project-technology:hover {
  background-color: rgba(255, 255, 255, 0.2);
}



.project-buttons {
  display: flex;
  gap: 1rem;
  margin-left: 2%;
  margin-bottom: 5%;
}

.project-button {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.project-button-view {
  background-color: #D4AF37;
  color: #000000;
}

.project-button-view:hover {
  background-color: #B08F26;
}

.project-button-enter {
  border: 2px solid #D4AF37;
  color: #D4AF37;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .work-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .work-container {
    grid-template-columns: 1fr;
  }

  .project-card {
    max-width: 100%;
  }

  .image-carousel {
    height: 200px; /* הגדרת גובה שונה למובייל */
  }
  .carousel-button {
    font-size: 1.2rem;
    padding: 0.3rem;
  }

  .project-content {
    padding: 1rem;
  }

  .project-title {
    font-size: 1.3rem;
  }


  .project-description {
    font-size: 0.9rem;
    height: 15%;
  }

  .project-technology {
    font-size: 0.7rem;
  }

  .project-button {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    margin-bottom: 5%;
  }
}

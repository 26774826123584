
/* Global styles */
body {
  background-color: #000000;
  color: #E0E0E0;
  font-family: 'Roboto', sans-serif;
}


.facts-section {
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.facts-card {
  background-color: #333333;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(212, 175, 55, 0.2);
  width: 80%;
  padding: 2rem;
  margin: 0 auto;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-side {
  width: 45%;
  padding: 10px;
}


.facts-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.text-side {
  width: 50%;
  padding: 10px;
}
.section-title {
  font-family: 'Playfair Display', serif;
  font-size: 2rem;
  color: #D4AF37;
  margin-bottom: 20px;
  text-align: left;
  margin-bottom: 5%;
  margin-left: 5%;
}

.facts-list {
  list-style: none;
  padding: 0;
  color: #e1d8d8;
}

.facts-list {
  list-style: none;
  padding: 0;
  color: #e1d8d8;
}


.facts-list li {
  font-size: 1rem;
  margin-bottom: 10px;
  position: relative;
  padding-left: 20px;
}

.facts-list li::before {
  content: '•';
  color: #D4AF37;
  position: absolute;
  left: 0;
}

.facts-list a {
  color: #D4AF37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.facts-list a:hover {
  color: #FFD700;
}


/* Animation Keyframes */
@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes lightning-animation {
  0% {
    top: -3%;
  }
  100% {
    top: 100%;
  }
}

/* Skills Section */
.skills-section {
  background-color: #222222;
  padding: 40px 0;
  text-align: center;
  margin-top: 40px;
  border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(212, 175, 55, 0.3);
}

.skills-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.skills-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 70%;
  height: 200px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
.skill-bar-container {
  width: 75px;
  height: 200px;
  display: flex;
  align-items: flex-end;
  position: relative;
}


.skill-bar {
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: 400% 400%;
  animation: gradient-animation 3s ease infinite;
}


.skill-percent {
  position: absolute;
  bottom: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  color: #000000;
}

.lightning-bolt {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.7);
  animation: lightning-animation 2s linear infinite;
  box-shadow: 0 0 10px #D4AF37;
}


.skill p {
  margin: 10px 0 0;
  font-size: 0.9rem;
  color: #D4AF37;
}


.skills-meter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 180px;
  padding: 10px;
  margin-right: 20px;
  position: relative;
}

.skill-level {
  font-size: 0.8rem;
  margin-bottom: 40%;
  color: #D4AF37;
  text-align: left;
}

.meter-line {
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: #D4AF37;
}

.meter-line {
  position: absolute;
  left: 10px;
  top: -4vh;
  width: 2px;
  height: 130%;
  background-color: #e1d8d8;
}

@media (max-width: 768px) {
  .facts-card {
    width: 90%;
    padding: 1rem;
  }


.facts-card {
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* הפחתת הצל */
  border-radius: 5%;
}

.content-wrapper {
  flex-direction: column;
}

.image-side, .text-side {
  width: 100%;
  padding: 10px 0;
}

.section-title {
  text-align: center;
  margin-bottom: 20px;
}

.skills-wrapper {
  width: 60%; /* הקטנת הרוחב של הסקילים */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transform: rotate(-90deg); /* סיבוב רק של הגרפים */
  margin-left: 10%;
}

.skills-container {
  display: flex;
  width: 100%;
  justify-content: center; /* ממקם את הסקילים באמצע */
}

.skills-meter {
  width: 60%; /* הקטנת הרוחב של הסקילים */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: 10%;
  height: 100%; /* התאמה לגובה הפס */
}



.skill {
  margin: 0 1px; /* רווח קטן בין כל בר */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skill-bar-container {
  width: 40px;
  height: 120px;
}

.skill-bar {
  width: 100%;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size: 400% 400%;
  animation: gradient-animation 2s ease infinite;
  margin-left: -150%;
  margin-bottom:3vh;
}

.skill-percent {
  font-size: 0.65rem; /* הקטנת הטקסט */
  font-weight: bold;
  transform: rotate(90deg); /* החזרת האחוזים למצב רגיל */
}

.skill p {
  font-size: 0.65rem; /* הקטנת הטקסט */
  margin: 5px 0;
  font-weight: bold;
  transform: rotate(90deg); /* החזרת שמות הסקילים למצב רגיל */
  position: relative;
  right: 150%; /* התאמה למיקום מול כל גרף */
  margin-top: 40%;
  text-align: left;
}

.meter-line {
  display: block; /* הצגת קו המדד */
  width: 2px;
  height: 125%; /* התאמת הקו לגובה החדש */
  margin-bottom: 10%;
  margin-left: -10%;
}

.skill-level {
  font-size: 0.75rem;
  text-align: left;
}
}

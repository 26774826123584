/* עיצוב כללי של ה-Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1rem 5%;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.navbar-title {
  font-size: 1.75rem;
  font-weight: bold;
  color: #D4AF37;
  text-shadow: 0 0 5px rgba(212, 175, 55, 0.5);
}

.nav-link {
  color: #FFFFFF;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  margin: 0 15px;
  position: relative;
  padding: 5px 0;
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #D4AF37;
  transition: width 0.3s ease;
}

.nav-link:hover {
  color: #D4AF37;
}

.nav-link:hover::after {
  width: 100%;
}

.hamburger-icon {
  display: none;
  cursor: pointer;
  font-size: 1.75rem;
  color: #D4AF37;
}

/* עיצוב למובייל */
@media (max-width: 768px) {
  .navbar {
    justify-content: flex-start;
    padding-left: 10px;
  }

  .navbar-title {
    font-size: 1.5rem;
    margin-left: 0;
  }

  .hamburger-icon {
    display: block;
    z-index: 1100;
    margin-left: auto;
  }

  .nav-links-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100vh;
    padding: 2rem;
    transition: right 0.4s ease;
    z-index: 1000;
    justify-content: center;
    align-items: center;
  }

  .nav-links-container.open {
    right: 0;
  }

  .nav-link {
    margin: 1.5rem 0;
    font-size: 1.2rem;
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.3s ease;
  }

  .nav-links-container.open .nav-link {
    opacity: 1;
    transform: translateY(0);
  }

  .hamburger-icon.open, .hamburger-icon.close {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

/* עיצוב למחשב עם אנימציה */
@media (min-width: 769px) {
  .nav-links-container {
    display: flex;
    justify-content: space-between;
  }

  .nav-link {
    opacity: 0;
    transform: translateY(-20px);
    animation: slideIn 0.4s ease-out forwards;
  }

  .nav-link:nth-child(1) {
    animation-delay: 0.1s;
  }

  .nav-link:nth-child(2) {
    animation-delay: 0.2s;
  }

  .nav-link:nth-child(3) {
    animation-delay: 0.3s;
  }

  .nav-link:nth-child(4) {
    animation-delay: 0.4s;
  }

  @keyframes slideIn {
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
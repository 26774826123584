body {
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 5vh;
  margin-top: -5vh;
}

.profile-card-1 {
  background-color: #333333;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 2rem;
  width: 70%;
  position: relative;
}

.text-container-1 {
  width: 60%;
}

.image-container-1 {
  width: 35%;
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translateY(-50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  height: 300px;
  width: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.profile-image-1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title-1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  font-weight: bold;
  color: #D4AF37;
  margin-bottom: 1rem;
}

.highlight-1 {
  color: #FFFFFF;
}

.description-1 {
  font-size: 1rem;
  color: #E0E0E0;
  margin-bottom: 1rem;
}

.details-1 {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.detail-item-1 {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #E0E0E0;
}

.detail-title-1 {
  font-weight: bold;
  color: #D4AF37;
  margin-right: 0.5rem;
}

.icon-1 {
  margin-right: 0.5rem;
  color: #FFFFFF;
}

/* עיצוב מותאם למובייל עם צבעים מעודכנים */
@media (max-width: 768px) {
  body {
    background-color: #000000;
  }

  .container-1 {
    margin-top: 200%;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .profile-card-1 {
    flex-direction: column;
    margin-top: 55%;
    width: 100%;
    padding: 1rem;
    background-color: #333333;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }

  .image-container-1 {
    position: relative;
    width: 80%;
    height: auto;
    margin: 0 auto 20px;
    margin-top: 10%;
    right: 0;
    transform: none;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    overflow: hidden;
  }

  .profile-image-1 {
    width: 100%;
    height: auto;
  }

  .text-container-1 {
    width: 100%;
    text-align: center;
    color: #E0E0E0;
  }

  .title-1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .description-1 {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: left;
    color: #E0E0E0;
  }

  .details-1 {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    text-align: left;
  }

  .detail-item-1 {
    justify-content: flex-start;
    font-size: 0.9rem;
    color: #E0E0E0;
  }

  .icon-1 {
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: #D4AF37;
  }
}